import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquest bolet presenta un mamelló groguenc ben visible al capell del que contrasta pel seu color lilaci. El capell de fins a 4 cm de diàmetre és viscós en temps humit i amb restes de cortina. Les làmines són lliures, de color argila a ocraci per la caiguda de les espores i el peu també lilós tornat-se blanquinós amb el temps. Els individus joves presenten cortina. Les espores són marró en massa, d’ovoides a amigdaliformes, de 9-11 x 5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      